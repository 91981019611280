<template>
  <CRow v-if="isAdmin">
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          Users
        </CCardHeader>
        <CCardBody>
          <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="50"
              clickable-rows
              :active-page="activePage"
              :pagination="{ doubleArrows: false, align: 'center'}"
              @page-change="pageChange"
              sorter
              :sorterValue="{column: 'id', asc: false}"
          >
            <template #auth="data">
              <td>
                <CButton @click="userSet(data.item.id)" color="info">Войти</CButton>
              </td>
            </template>
            <template #status="data">
              <td>
                <CBadge v-if="data.item.admin" :color="getBadge(data.item)">
                  {{data.item.admin ? 'админ' : ''}}
                </CBadge>
              </td>
            </template>
            <template #tariff="data">
              <td>
                <span v-if="data.item.tariff">
                  {{data.item.tariff}}
                </span>
                <span v-if="data.item.tariff_expired">
                  до {{data.item.tariff_expired}}
                </span>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>

</template>

<script>

import axios from '@/modules/axios'

export default {
  name: 'Users',
  data () {
    return {
      items: [ ],
      fields: [
        { key: 'id', label: '#', },
        { key: 'name', label: 'Имя', _classes: 'font-weight-bold' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Телефон' },
        { key: 'registered', label: 'Регистрация' },
        { key: 'promo', label: 'Промокод' },
        { key: 'tariff', label: 'Тариф' },
        { key: 'status', label: '' },
        { key: 'auth', label: '' }
      ],
      activePage: 1
    }
  },
  created() {
    this.getUsers()
  },
  computed: {
    isAdmin() {
      return !!this.$store.getters.user.admin;
    }
  },
  methods: {
    getBadge(item) {
      if (item.admin) {
        return 'danger';
      }

      return 'primary';

        // case 'Active': return 'success'
        // case 'Inactive': return 'secondary'
        // case 'Pending': return 'warning'
        // case 'Banned': return 'danger'
        // default: 'primary'
    },
    getUsers () {
      axios.get('/admin/users-list').then((res) => {
        this.items = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'admin', text: error.response.data.message, type: 'error' })
      })
    },
    rowClicked (item) {
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    userSet(id) {
      if (confirm('Авторизоваться под этим пользователем?')) {
        axios.post('/auth/change', {
          userId: id
        }, {
          headers: {'content-type': 'application/json'}
        }).then((res) => {
          const user = res.data.data;
          this.$store.dispatch('setUser', user);
          localStorage.setItem('jwt', user.jwt)
          this.$router.push('/calendar/list')
        }).catch((error) => {
          this.$notify({group: 'admin', text: error.response?.data.message, type: 'error'})
        });
      }
    },
  }
}
</script>
<style lang="scss">
form button {
  margin-right: 2em;
}
</style>
